.home {
  text-align: center;
  padding: 1rem;
  height: calc(100vh - 90px);
  position: relative;
  width: 100%;
}
.mainContent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55vh;
  max-width: 603px;
  margin: auto;
  /* position: relative; */
  /* position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0; */
}
.mainContent p {
  margin: 1em 0 2em;
}
.mainContent h2 {
  margin: 1em 0;
}
.home h1:first-child {
  font-size: 5.8rem;
  color: #0a083b;
  margin-bottom: 0.75rem;
}
.searchArea {
  display: flex;
  position: relative;
  border-radius: 30px;
  padding: 0.7rem;
  width: 550px;
  box-shadow: 0px 0px 5px 0px var(--darkGrayColor);
  margin: auto;
}
.searchArea input {
  border: none;
  background-color: #ffff !important;
  width: 90%;
}
.searchArea input:focus {
  box-shadow: none !important;
  border-color: transparent !important;
}

.searchIcon {
  position: absolute;
  top: 25px;
  right: 25px;
  font-size: 1.4rem;
  transform: translateY(-50%);
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
}

.searchLinks {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
}
.searchLinks a {
  border: 1px solid #0a083b;
  padding: 0.2em 1em;
  border-radius: 15px;
  font-weight: 600;
  margin-right: 1em;
  color: #0a083b;
  /* box-shadow: 0px 2px 10px -5px #0a083b; */
  transition: 200ms all ease-in-out;
}
/* .searchLinks a:hover {
  box-shadow: 0px 2px 10px -1px #0a083b;
} */

.notFoundSearchLinks {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  margin-top: 2em;
  max-width: 800px;
}
.notFoundSearchLinks a {
  color: black;
  font-size: 1.8em;
}
.downArrow {
  position: absolute;
  bottom: 4em;
  font-size: 2em;
  color: black;
  display: inline-block;
}
.submitBtn {
  color: #0a083b;
  margin-top: 1.5em;
  display: inline-block;
  font-size: 1.7em;
  background: transparent;
  border: none;
}
.submitBtn:hover {
  color: #0a083b;
}
.searchContainer button {
  display: none !important;
}

.homeSearchContainer {
  /* margin-top: 3em; */
}
.homeSearchContainer > header {
  display: none;
}

.searchSuffix {
  height: 100%;
  width: 105px;
  margin: 0 5px;
  padding: 0;
  position: absolute;
  right: 3.3em;
  z-index: 999;
  top: 1em;
}

.searchSuffix a {
  color: #0a083b;
}

/* .searchLinks a:last-child {
  margin-right: 0em;
} */
@media only screen and (max-width: 768px) {
  .searchSuffix {
    top: 1.7em;
  }

  .searchArea {
    width: 380px;
  }
  .submitBtn {
    margin-top: 1em;
    font-size: 1.2em;
  }
}
@media only screen and (max-width: 576px) {
  .searchLinks {
    flex-wrap: nowrap;
  }
  .searchLinks a {
    margin-right: 0.5em;
    margin-bottom: 1em;
  }
  .home {
    height: calc(100vh - 141px);
  }
  .mainContent {
    height: 70vh;
  }
  .submitBtn {
    margin-top: 0.6em;
    font-size: 1.1em;
  }

  .mainContent > div:first-child > h1:nth-child(2) {
    font-size: 1.1em !important;
  }
}

.mainContent h1 {
  font-size: 5em !important;
}
